<template>
  <div class="btn-group" :class="{'opacity-button': !isMe}">
    <button class="btn btnoutline dropdown-toggle" type="button" id="dropdownMenuClickableInside"
      data-bs-toggle="dropdown" data-bs-auto-close="outside" ref="passengerDropdown">
      <strong>{{$t("sabre.search-panel.passenger-selection")}} </strong>
      <span><i class="fa-solid fa-user"></i></span>
      <small>{{compositionString.length > 15 ? `${compositionString.slice(0,15)} ...` : compositionString}}</small>

    </button>

    <select-passenger :propsClassName="dropdownClassName"
    type="dropdown" aria-labelledby="dropdownMenuClickableInside" @close="closeDropdown"/>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dropdownOpenState from '@/sabre/common/mixins/dropdownOpenState';

export default {
  name: 'fh-composition-select',
  mixins: [dropdownOpenState],
  components: {
    SelectPassenger: () => import('./SelectComposition'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      adults: 'GET_FH_ADULTS',
      children: 'GET_FH_CHILDREN',
      babies: 'GET_FH_INFANTS',
      seniors: 'GET_FH_PENSIONERS',
    }),
    compositionString() {
      const { adults, children, babies, seniors } = this;
      let str = '';
      if (adults) str += `${adults} ${this.$t('sabre.composition.adults')}`;
      if (children) str += ` ${children} ${this.$t('sabre.composition.children')}`;
      if (babies) str += ` ${babies} ${this.$t('sabre.composition.infants')}`;
      if (seniors) str += ` ${seniors} ${this.$t('sabre.composition.seniors')}`;
      return str;
    },
    dropdownClassName() {
      const { showed, device } = this;
      return `dropdown-menu fromwheredropdown ${showed ? 'show' : ''}${device === 'mobile' ? ' fixedbox' : ''}`;
    },
  },
  data: () => ({
    showed: false,
    componentName: 'composition',
  }),
  methods: {
    closeDropdown() {
      this.showed = false;
      window.bootstrap.Dropdown.getOrCreateInstance(this.$refs.passengerDropdown).hide();
    },
    openDropdown() {
      this.showed = true;
    },
  },
};
</script>

<style scoped>
/* .dropdown-menu.fromwheredropdown:not(.fixedbox) {
  top: auto !important;
  left: auto !important;
} */
.dropdown-menu.fromwheredropdown:not(.fixedbox) {
  max-width: 300px;
  min-width: 300px;
}
</style>
